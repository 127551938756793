import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ApiSidebar from "../../components/api-sidebar"
import ApiDocItem from "../../components/api-doc-item"
import ApiDocFooter from "../../components/api-doc-footer"
import ApiParameterTable from "../../components/api-parameter-table"
import ApiKeyField from "../../components/api-key-field"
import CodeBlock from "../../components/code-block"
import CodeTransformer from "../../components/code-transformer"

import apiSettings from "../../utils/api-settings"
import Transformer from "../../utils/code-transformer/transformer"

const endpoint = "play/sound"

const transformer = new Transformer({
  method: "post",
  options: {
    endpoint: endpoint,
    apiBaseUrl: apiSettings.apiBaseUrl,
    apiKey: apiSettings.apiKey,
  },
})

const ApiPlaySoundPage = () => (
  <Layout sidebar={ApiSidebar()} title="Play Sound API">
    <SEO title="Play Sound API" />
    <p>
      Use the <i>Play Sound API</i> to play predefined sounds from Quak on your
      Sonos speakers <b>for free</b>. Just replace{" "}
      <code>{apiSettings.apiKeyPlaceholder}</code> with your own key.
    </p>

    <ApiKeyField />

    <ApiDocItem>
      <h3>Endpoint</h3>
      <CodeBlock
        platform="bash"
        code={{
          bash: {
            code: `POST  ${apiSettings.apiBaseUrl}/${endpoint}`,
            codeToCopy: `${apiSettings.apiBaseUrl}/${endpoint}`,
          },
        }}
        showPlatformPicker={false}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Basic example</h3>
      <p>
        This will play the given sound with your default settings on your Sonos
        speakers. <code>sound</code> is the only required parameter.{" "}
        <Link to="/api/list-sounds/">Get a list of all available sounds.</Link>
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            sound: "quakquak",
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Set volume & speakers</h3>
      <p>
        Set <code>targets</code> (speakers, groups, ...) and <code>volume</code>{" "}
        to override your global defaults.{" "}
        <Link to="/api/list-targets/">
          Get a list of all available targets.
        </Link>
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            sound: "quakquak",
            targets: "livingroom",
            volume: 10,
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Play on multiple targets</h3>
      <p>
        To play on multiple targets at the same time, set the{" "}
        <code>targets</code> parameters like this.
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            sound: "quakquak",
            targets: ["livingroom", "bath"],
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Preview mode</h3>
      <p>
        If you just want to preview the playback, you can pass{" "}
        <code>preview</code> and nothing will be played on your Sonos speakers.
        But the JSON response will include an URL to the audio file.
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            sound: "quakquak",
            preview: true,
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Play only on specific days and times</h3>
      <p>
        Use the <code>days</code> and <code>hours</code> parameters to specify
        when to play the sound. If week day and time (
        <a href="https://app.quak.sh/settings" target="_blank">
          check your time zone
        </a>
        ) does not match nothing will be played. Notifications will not be
        queued.
      </p>
      <p>
        Let's say you want to play a sound in the office every time a sale in
        your shop is happening. It would be a waste of energy if the sound would
        be played 24/7 if you are not in the office all the time.
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            sound: "cash",
            days: "mo-fr",
            hours: "8-18",
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Request Parameters</h3>
      <p>This is a full list of the request parameters you can use:</p>
      <ApiParameterTable type="SOUND" />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Example Response</h3>
      <p>
        If the request was successful you will receive a response like this one.
        The <code>uuid</code> is the unique identifier you can use to get
        updates regarding playback status.
      </p>
      <p>
        {" "}
        Directly after submitting <code>status</code> is{" "}
        <code className="value">PENDING</code>. Only if you use{" "}
        <code>preview</code> the <code>status</code> is{" "}
        <code className="value">DONE</code>.{" "}
        <Link to="/api/response-codes/">Learn more about response codes.</Link>
      </p>
      <CodeBlock
        platform="json"
        code={{
          json: {
            code: `{
  "uuid": "91519669-63c5-4056-946e-e0b01fa38efa",
  "createdAt": "2019-05-21T20:40:55.052Z",
  "type": "SOUND",
  "status": "PENDING",
  "audioUrl": "https://cdn.quak.sh/sounds/6424/f9a7/6424f9a7-618b-4b7e-b142-95eea176bafb.mp3",
  "audioId": "6424f9a7-618b-4b7e-b142-95eea176bafb",
  "audioUrlExpiresAt": null,
  "length": 0.5,
  "credits": 0,
  "fromCache": true,
  "players": [
    {
      "uuid": "f89fcd4d-05d1-4231-a954-ca5f5cead5a1",
      "slug": "livingroom",
      "name": "Livingroom",
      "provider": "SONOS",
      "status": "PENDING"
    }
  ],
  "params": {
    "volume": 30,
    "targets": [
      "livingroom"
    ],
    "preview": false,
    "priority": false,
    "sound": "quakquak"
  },
  "sound": {
    "uuid": "3da2e845-4c7b-4750-92d2-2507c6dfc6f6",
    "slug": "quakquak",
    "name": "Quak Quak"
  }
}`,
          },
        }}
      />
    </ApiDocItem>

    <ApiDocFooter active="/api/play-sound/" />
  </Layout>
)

export default ApiPlaySoundPage
